<template>
    <div class="uk-flex uk-flex-column uk-flex-center uk-flex-middle" style="min-height: 100%;">
        <p class="uk-text-lead uk-text-bold">There is no Internet connection</p>
        <button type="button" class="uk-button uk-button-primary uk-border-rounded" @click="reloadPage">Reload</button>
    </div>
</template>

<script>
export default {
    name: 'NoInternetConnection',
    methods: {
        reloadPage() {
            window.location.reload();
        }
    }
};
</script>
